import { Link } from "react-router-dom"
import Button from "@mui/material/Button"

function App() {
   return (
      <p className="home">
         <Link to="/calendar">
         <Button variant="contained">Access to data</Button></Link>
         <br />
         <br />
         <img src="/catalogue/nenusun.png" alt="Nenu-sun logo" width="500" />
         <br />
         <br />
         <Link to="/introduction">
         <Button variant="contained">Introduction to radio observations</Button></Link>
      </p>
   )
}

export default App
