/* REACT */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from "@mui/material/Button"
import { Link } from "react-router-dom"

// import "./Introduction.css";

export default function Introduction() {

   return (
    <div>
        
    <Card sx={{ display:'block', maxWidth: 800, height: '75vh', mx: "auto", my: "50px" }}>
        <CardContent>
        <iframe 
            title="Introduction"
            style={{width: "100%", height: "70vh", borderStyle: "none" }}  
            src="/catalogue/Introduction.html"></iframe>  
        </CardContent>
    </Card>
    <Link to="/">
         <Button variant="contained">Home page</Button></Link>
    </div>
   )
}
